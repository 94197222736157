.payment-container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    padding: 20px;
}

.payment-card {
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    width: 100%;
    max-width: 500px;
}

.payment-header {
    text-align: center;
    margin-bottom: 2rem;
}

.payment-header h2 {
    color: #333;
    margin-bottom: 1.5rem;
}

.steps-indicator {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

.step {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    color: #666;
    position: relative;
}

.step.active {
    background: #4CAF50;
    color: white;
}

.step:not(:last-child):after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: #e0e0e0;
    right: -100%;
    top: 50%;
    transform: translateY(-50%);
}

.step.active:not(:last-child):after {
    background: #4CAF50;
}

.payment-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.form-group label {
    color: #666;
    font-size: 0.9rem;
}

.form-group input {
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
}

.primary-button {
    background: #4CAF50;
    color: white;
    border: none;
    padding: 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.3s ease;
}

.primary-button:hover {
    background: #45a049;
}

.primary-button:disabled {
    background: #cccccc;
    cursor: not-allowed;
}

.payment-success {
    text-align: center;
}

.success-icon {
    width: 60px;
    height: 60px;
    background: #4CAF50;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1rem;
    color: white;
    font-size: 2rem;
}

.payment-section {
    text-align: center;
}

.payment-section h3 {
    margin-bottom: 1rem;
    color: #333;
}

.payment-section p {
    color: #666;
    margin-bottom: 1.5rem;
}

/* Custom toast styles */
.Toastify__toast {
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 12px;
}

.Toastify__toast--error {
    background: #fff1f0;
    color: #cf1322;
    border: 1px solid #ffa39e;
}

.Toastify__toast--success {
    background: #f6ffed;
    color: #389e0d;
    border: 1px solid #b7eb8f;
}

.Toastify__toast-body {
    font-size: 14px;
    line-height: 1.5;
}

.Toastify__progress-bar {
    height: 3px;
}

.Toastify__close-button {
    color: currentColor;
    opacity: 0.7;
}

.Toastify__close-button:hover {
    opacity: 1;
} 